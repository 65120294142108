import React, { Component } from 'react';
import Gentablet from "../../../images/screenshots/aggro.jpg";
import WaterBlaze from "../../../images/trainingbanner.jpg";

const styles = {
    backbannertrain: {
        backgroundImage: `url(${WaterBlaze})`
	},
	backscreen: {
        backgroundImage: `url(${Gentablet})`
    }
	
};

class Trainingaggregatorinternalbanner extends Component {
    render() {

        return (
            <div>
                <section className="internalbanner" style={styles.backbannertrain}>
	<div className="overfunk"></div>
	<div className="container">
		<div className="columns is-marginless">
			<div className="column is-half">
				<p className="topsubtitle">Search for trainers</p>
				<h1 className="midsize">Training Aggregator Portal</h1>
				<p className="undersubtitle">Enable the clients and suppliers to centralise and manage training services</p>
			</div>
		</div>
	</div>
</section>
<section className="under-internalbanner graddown">
	<div className="threeboxes">
		<div className="container">
			<div className="boxcard norise">
				<div className="columns is-marginless">
					<div className="column is-10">
						<h2>A training-portal for clients and suppliers</h2><hr/>
						<p>The web-based application portal enables the clients and suppliers to centralise and manage training services.</p><br/>
					</div>

				</div>
			</div>
		</div>
	</div>
	</section>
	<section className="largerpicsplit graddown">
	<div className="columns is-marginless">
					<div className="column is-4 is-offset-2 pictext">
						<h3>Centralised training management</h3>
						<p className="bigpee">The portal is designed to : </p>
						<ul>
						<li><span className="highlightlistspan">Search</span> for auditors in according to area, date and budget</li>
						<li><span className="highlightlistspan">Allow</span> bookings to be placed</li>
						<li><span className="highlightlistspan">Allow</span> payments to be made</li>
						<li><span className="highlightlistspan">Upload</span> of accreditations of training companies</li>
						<li><span className="highlightlistspan">Reporting</span> of invoices sent by training companies</li>
						<li><span className="highlightlistspan">Reporting</span> of budget spent by clients</li>
						<li><span className="highlightlistspan">Scoring</span> mechanism to rank and rate trainers</li>
						</ul>
					</div>
					<div className="column is-5 backcover"  style={styles.backscreen}>
							<img src={Gentablet} className="hideo"/>

					</div>
				</div>
	</section>
            </div>
        );
    }
}

export default Trainingaggregatorinternalbanner;