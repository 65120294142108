import React from "react"

import "../style/bulma.css"
import "../style/coolharbour-tech.css"
import "../style/coolharbour-gentext.css"
import "../style/coolharbour-tech-internal-livelab.css"
import "../style/swiper.css"


import Layout from "../components/layout"
import Trainingaggregatorinternalbanner from "../components/internalpages/training-aggregator/trainingaggregatorinternalbanner"

import Contactform from "../components/homepage/contactform"
import SEO from "../components/seo"
import Coolfooter from "../components/coolfooter"
import Bluebackstatement from "../components/extras/bluebackstatement"


const statement = ["Training Aggregator is a great way to ", <span>source quality training</span>, " in your area"];
let allProps = {
  slidertitle_a:"Search for auditors in according to area, date and budget",
  slidertitle_b:"Allow bookings to be placed",
  slidertitle_c:"Allow payments to be made",
  slidertitle_d:"Upload of accreditations of training companies",
  slidertitle_e:"Reporting of invoices sent by training companies",
  slidertitle_f:"Reporting of budget spent by clients",
  slidertitle_g:"Scoring mechanism to rank and rate trainers",
  }




const Trainagg = () => (
  <Layout>
    <SEO title="Training Aggregator" />
    <Trainingaggregatorinternalbanner Proppage = {allProps}/>
    <Bluebackstatement PassStatement = {statement}/>
   <Contactform />
   <Coolfooter />
  </Layout>
)

export default Trainagg